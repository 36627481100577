import React from 'react'
import { Box } from '@mui/material';

const Embed = ({ embed }) => (
  <Box sx={{ '& iframe': { width: '100%', position: 'absolute', top: 0, height: '100%' }}}>
    <div
      style={{
        position: 'relative',
        paddingTop: '56.25%',
        width: '100%',
      }}
      dangerouslySetInnerHTML={ {__html: embed}} />
  </Box>
)

export default Embed
