import { Typography } from '@mui/material'
import React from 'react'

const Caption = ({ children, ...props}) => (
  <Typography
    align="center"
    variant="overline"
    {...props}
  >
    {children}
  </Typography>
)

export default Caption
